<template>
  <div class="container">
    <!-- 顶部视频 -->
    <div
      class="banner"
      :style="`background-image: url(${banData.Path});`"
      @click="playVideo"
    >
      <!-- <div class="play-button">
        <img src="@/assets/newImg/home_ban_ico.png" />
      </div> -->
    </div>
    <!-- 视频播放组件 -->
    <div v-if="isVideoPlaying" class="video-container">
      <div class="x_end" @click.stop="closeVideo">
        <van-icon name="close" color="#fff" size="32" />
      </div>
      <video ref="videoPlayer" controls autoplay>
        <source :src="banData.VedioPath" />
      </video>
    </div>
    <!-- 分享 -->
    <div class="topimg" @click="toShare">
      <img src="@/assets/newImg/home_share.png" />
    </div>
    <!-- 按钮 -->
    <div class="btnInfo">
      <div class="btn_item">
        <div class="btn_i_i" @click="$router.push({ name: 'Register' })">
          <img src="@/assets/newImg/home_b_promotion.png" />
          <div class="btn_i_i_txt">{{ $t('home').Register.toUpperCase() }}</div>
        </div>
        <div class="btn_i_line_1"></div>
        <div class="btn_i_i" @click="$router.push({ name: 'Direct' })">
          <img src="@/assets/newImg/home_b_direct.png" />
          <div class="btn_i_i_txt">
            {{ $t('home').DirectPush.toUpperCase() }}
          </div>
        </div>
      </div>
      <div class="item_line"></div>
      <div class="btn_item">
        <div class="btn_i_i" @click="$router.push({ name: 'Audit' })">
          <img src="@/assets/newImg/home_b_suggestion.png" />
          <div class="btn_i_i_txt">{{ $t('home').Audit.toUpperCase() }}</div>
        </div>
        <div class="btn_i_line_2"></div>
        <div class="btn_i_i" @click="goService">
          <img src="@/assets/newImg/home_b_ecosphere.png" />
          <div class="btn_i_i_txt">{{ $t('home').Service.toUpperCase() }}</div>
        </div>
      </div>
      <div class="item_line"></div>
      <div class="btn_item">
        <div
          class="btn_i_i"
          @click="
            $router.push({ name: 'Direct', query: { Type: 'statistics' } })
          "
        >
          <img src="@/assets/newImg/home_b_team.png" />
          <div class="btn_i_i_txt">{{ $t('home').MyTeam.toUpperCase() }}</div>
        </div>
        <div class="btn_i_line_3"></div>
        <div class="btn_i_i" @click="$router.push({ name: 'Donate' })">
          <img src="@/assets/newImg/home_b_services.png" />
          <div class="btn_i_i_txt">{{ $t('home').Donate.toUpperCase() }}</div>
        </div>
      </div>
    </div>
    <TabBar :active="'Home'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
import TabBar from '@/components/tabBarNew'

export default {
  components: {
    TabBar
  },
  data() {
    return {
      isVideoPlaying: false,
      banData: {}
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['basicConfig', 'lang', 'connection', 'baseMsg'])
  },
  mounted() {
    this.getRotation()
  },
  methods: {
    async getRotation() {
      const res = await userApi.getRotation()
      this.banData = res.Data[0]
    },
    playVideo() {
      // this.isVideoPlaying = true
      // this.$nextTick(() => {
      //   this.$refs.videoPlayer.play()
      // })
    },
    closeVideo() {
      this.$refs.videoPlayer.pause()
      this.isVideoPlaying = false
    },
    //联系客服
    async goService() {
      const res = await userApi.getClientConfig()
      this.$router.push({
        name: 'IframePage',
        query: { IframePage: res.Data.ServiceUrl }
      })
      //  location.href = res.Data.ServiceUrl
    },
    async toShare() {
      const aBase = await userApi.getAccountBase()
      if (aBase.GradeType < 5) this.$toast(this.$t('user').ShareLimtMsg)
      else this.$router.push({ name: 'Share' })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  background: #fff;
  padding-bottom: 50px;
}
.banner {
  width: 100%;
  height: 250px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .play-button {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .play-button img {
    width: 40px;
  }
}
.topimg {
  margin: -50px 10px 0 10px;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
  }
}
.btnInfo {
  background: #ffffff;
  border-radius: 10px;
  margin: -48px 0 0 0;
  padding-top: 40px;
  .btn_item {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: 0 40px 0 40px;
    img {
      width: 75px;
    }
    .btn_i_i {
      text-align: center;
      margin-top: 25px;
      margin-bottom: 25px;
      width: 40%;
      .btn_i_i_txt {
        margin-top: 15px;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 15px;
        color: #4a30b7;
      }
    }
    .btn_i_line_1 {
      margin-top: 25px;
      width: 0.5px;
      background: #4a30b7;
      opacity: 0.2;
    }
    .btn_i_line_2 {
      width: 0.5px;
      background: #4a30b7;
      opacity: 0.2;
    }
    .btn_i_line_3 {
      margin-bottom: 25px;
      width: 0.5px;
      background: #4a30b7;
      opacity: 0.2;
    }
  }
  .item_line {
    margin: 0 20px;
    height: 0.5px;
    background: #4a30b7;
    opacity: 0.2;
  }
}
::v-deep .van-dialog {
  background-color: transparent;
  width: 320px;
}
.x_end {
  text-align: right;
}
.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 9999;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Or 'cover' if you want the video to fill the container */
}
</style>
